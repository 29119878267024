
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    WhiteBlock,
    TmExpansion,
    DetailsHero,
    InfoCard,
    TmButton,
    TmFormLine,
    ChatsSettingsPage,
  },
  props: {
    hidePreview: {
      type: Boolean,
    },
    hideMessageInfo: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  setup(props) {
    const { openModal } = useModals()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Auto-responders', url: { name: 'base.chats.settings.autoResponders' } },
      { label: props.title },
    ]
    const outsideHours = ref(true)
    const withinHours = ref(true)
    const message = ref('Hello {{firstName}},\n\nThank you for reaching out to Textmagic!\nWe are in the office from Monday to Friday from 7:30 AM to 6:00 PM and will respond to you as soon as possible.')
    const openPreview = () => {
      openModal('chatsMessageDetails', {
        items: [
          {
            label: 'Message content',
            value: 'A quick reminder of your appointment at 11:15am on June 8th at ABC Dentists. If you need to change this please call us on 01615555555 or reply to this text.',
          },
          {
            label: 'Total characters',
            value: '212 characters',
          },
          {
            label: 'Message parts',
            value: '1 part',
          },
        ],
        modalTitle: 'Auto-responder SMS preview',
        messageBubble: true,
      })
    }

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.ai,
    ]

    return {
      openPreview,
      openModal,
      message,
      withinHours,
      breadcrumbs,
      outsideHours,
      btnsList,
    }
  },
})
